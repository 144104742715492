<template>
  <div>
    <v-snackbar
      v-model="vrNotify.visible"
      :timeout="timeout"
      top="top"
      right="right"
      :color="vrNotify.type"
      class="mt-14"
    >
      {{ vrNotify.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          class="pa-0"
          color="white"
          text
          v-bind="attrs"
          @click.native="vrNotify.visible = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import store from "@/store/index";
export default {
  data: () => ({
    //snackbar: false,
    //text: "My timeout is set to 4 sec.",
    timeout: 2000,
    position: "top",
    color: "warning",
  }),
  props: ["notify"],
  watch: {
    // snackbar: function (val) {
    // },
  },
  mounted() {
    //this.snackbar = false
  },
  computed: {
    vrNotify() {
      return store.state.notify;
    },
  },
};
</script>
